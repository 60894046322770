import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'Menú principal',
    moduleName: '',
    icon: '',
    class: '',
    groupTitle: true,
    submenu: []
  },
  {
    path: '',
    title: 'Administración',
    moduleName: 'users',
    icon: 'monitor',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'roles',
        title: 'Roles',
        moduleName: 'roles',
        icon: 'crown',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'users',
        title: 'Usuarios',
        moduleName: 'users',
        icon: 'person',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'views',
        title: 'Vistas',
        moduleName: 'views',
        icon: 'eye',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'services',
        title: 'Servicios',
        moduleName: 'services',
        icon: 'branch',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
    ]
  },
  {
    path: '',
    title: 'Catalogos',
    moduleName: 'catalogs',
    icon: 'book',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'companies',
        title: 'Compañias',
        moduleName: 'companies',
        icon: 'company',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'branches',
        title: 'Sucursales',
        moduleName: 'branches',
        icon: 'store',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'zip_codes',
        title: 'Codigos Postales',
        moduleName: 'zip_codes',
        icon: 'mail',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
    ]
  },
  {
    path: '',
    title: 'Sucursal',
    moduleName: 'branch',
    icon: 'home',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'stations',
        title: 'Estaciones',
        moduleName: 'stations',
        icon: 'module',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'cameras',
        title: 'Camaras',
        moduleName: 'cameras',
        icon: 'camera',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
    ]
  }
];
