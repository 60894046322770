import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/service/auth.service';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { InGlobalRes } from 'src/app/core/models/globalRes.interface';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.sass'] // Asegúrate de que la extensión sea .scss en Angular 13
})
export class SignupComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  error = '';
  hide = true;

  registerProgressBtn: MatProgressButtonOptions = {
    active: false,
    text: 'Registrarse',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: true,
    disabled: false,
    customClass: 'register100-form-btn',
    mode: 'indeterminate'
  };

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      username: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required]
    });
  }

  get f() { return this.registerForm.controls; }

  async register(): Promise<void> {
    this.submitted = true;
    this.error = '';

    if (this.registerForm.invalid) {
      this.error = 'Verifica tus datos';
      return;
    }

    const formData = this.registerForm.value;
    formData['role_id'] = 3;
    if (formData.password !== formData.confirmPassword) {
      this.error = 'Las contraseñas no coinciden';
      return;
    }

    this.authService.register(formData)
      .subscribe({
        next: (result: InGlobalRes) => {
          if (result.status) {
            this.router.navigate(['/authentication/signin']);
          } else {
            this.error = result.msg;
          }
        },
        error: (err: any) => {
          console.log(err);
          this.error = err;
        },
        complete: () => {
          this.registerProgressBtn.active = false;
        }
      });
  }
}
