import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.sass']
})
export class ContentLayoutComponent implements OnInit {
  breadscrums = [
    {
      title: '',
      items: [''],
      active: ''
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
