<div class="container-fluid p-0">
    <div class="row g-0">
      <div class="col-lg-4">
        <div class="auth-content p-4 d-flex align-items-center min-vh-100">
          <div class="w-100">
            <div class="row justify-content-center">
              <div class="col-lg-9">
                <div>
                  <div class="text-center">
                    <div>
                      <a routerLink="" class="logo-full"><img src="assets/images/logo-full.png" alt="logo-full"></a>
                    </div>

                    <h4 class="font-20 mt-4">Restablecer contraseña</h4>
                    <p class="text-muted">Escribe tu nueva contraseña</p>
                  </div>

                  <div *ngIf="showForm; else Loading" class="p-2 mt-5">
                    <form [formGroup]="form">
                        <div class="form-group auth-form-group mb-4">
                            <mat-icon matSuffix class="auth-page-icon">vpn_key</mat-icon>
                            <input type="password" formControlName="password" class="form-control auth-control"
                              id="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                              placeholder="Contraseña nueva" />
                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                              <div *ngIf="f.password.errors.required">Contraseña requerida</div>
                            </div>
                        </div>

                        <div class="form-group auth-form-group mb-4">
                            <mat-icon matSuffix class="auth-page-icon">vpn_key</mat-icon>
                            <input type="password" formControlName="password_confirmation" class="form-control auth-control"
                              id="password_confirmation" [ngClass]="{ 'is-invalid': submitted && f.password_confirmation.errors }"
                              placeholder="Confirma tu contraseña nueva" />
                            <div *ngIf="submitted && f.password_confirmation.errors" class="invalid-feedback">
                              <div *ngIf="f.password_confirmation.errors.required">Contraseña requerida</div>
                            </div>
                        </div>

                        <div class="container-login100-form-btn">
                            <mat-spinner-button 
                            ngClass="spinner-btn" 
                            style="width: 100%;"
                            [options]="resetPassProgressBtn"
                            [active]="resetPassProgressBtn.active"
                            (btnClick)="save()">
                            </mat-spinner-button>
                        </div>
                    </form>
                  </div>
                  
                  <ng-template #Loading>
                      <div class="d-flex justify-content-center pt-4">
                          <mat-spinner></mat-spinner>
                      </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="auth-bg">
          <div class="bg-overlay"></div>
        </div>
      </div>
    </div>
  </div>
