<div class="container-fluid p-0">
  <div class="row g-0">
    <div class="col-lg-4">
      <div class="auth-content p-4 d-flex align-items-center min-vh-100">
        <div class="w-100">
          <div class="row justify-content-center">
            <div class="col-lg-9">
              <div>
                <div class="text-center">
                  <div>
                    <a routerLink="" class="logo-full"><img src="assets/images/logo-full.png" alt="logo-full"></a>
                  </div>

                  <h4 class="font-20 mt-4">¿Se te olvidó tu contraseña?</h4>
                  <p class="text-muted">Ingresa tu email para restablecer tu contraseña</p>
                </div>

                <div class="p-2 mt-5">
                  <form [formGroup]="loginForm">
                    <div class="form-group auth-form-group mb-4">
                      <mat-icon matSuffix class="auth-page-icon">email</mat-icon>
                      <input type="email" formControlName="email" class="form-control auth-control" id="email"
                        placeholder="Correo electrónico" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                      <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">Correo requerido</div>
                        <div *ngIf="f.email.errors.email">Debe ser un correo válido</div>
                      </div>
                    </div>

                    <div class="container-login100-form-btn">
                      <div class="container-login100-form-btn">
                        <mat-spinner-button 
                          ngClass="spinner-btn" 
                          style="width: 100%;"
                          [options]="continueProgressBtn"
                          [active]="continueProgressBtn.active"
                          (btnClick)="resetPassword()">
                        </mat-spinner-button>
                      </div>
                    </div>

                  </form>
                </div>

                <div class="mt-5 text-center">
                  <p>¿Quieres iniciar sesión? <a routerLink="/authentication/signin" class="font-weight-medium text-primary">
                    ir al inicio </a> </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-8">
      <div class="auth-bg">
        <div class="bg-overlay"></div>
      </div>
    </div>
  </div>
</div>
