import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';
import { IResetPassword, IUser, IUserAccessRes, IUserRes } from 'src/app/interfaces/user.interface';
import { InGlobalRes } from '../models/globalRes.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<IUser>;
  public currentUser: Observable<IUser>;
  API_URL = environment.apiUrl;
  API_URL_NO_PREFIX = environment.apiNoPrefix;
  route = '/users';

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<IUser>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): IUser {
    return this.currentUserSubject.value;
  }

  fakeLogin(username: string, password: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/authenticate`, {
        username,
        password
      })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  login(email: string, password: string): Observable<IUserAccessRes> {
    return this.http.post<IUserAccessRes>(`${this.API_URL}/login`, {
      email,
      password
    }).pipe (map ((data: IUserAccessRes) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      data.data.user.token = data.data.accessToken.token;
      localStorage.setItem('currentUser', JSON.stringify(data.data.user));
      localStorage.setItem('token', JSON.stringify(data.data.accessToken.token));
      // localStorage.setItem('token', JSON.stringify(data.data.accessToken.token));
      this.currentUserSubject.next(data.data.user);
      return data;
    }));
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.get<IUserAccessRes>(`${this.API_URL}${this.route}/forgot-password/${email}?email=${email}`, {
    });
  }

  resetPassword(data: IResetPassword, email: string, signature: string): Observable<IUserRes> {
    const params = new HttpParams().append('signature', signature);
    return this.http.post<IUserRes>(`${this.API_URL_NO_PREFIX}/reset-password/${email}`,
    data, { params });
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    return of({ success: false });
  }

  register(user : IUser): Observable<any>{
    return this.http.post<InGlobalRes>(`${this.API_URL}/users`, user)
  }

  fakeRegister(user : IUser) {
    return this.http.post<any>(`${environment.apiUrl}/fakeregister`, {user})
  }
}
